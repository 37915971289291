$(document).ready(function () {
    var windowWidth = window.innerWidth;

    onScrollInit('.os-animation', $('.animationOn .os-animation-holder'));


    $('.ajaxbutton').click(function (e) {
        e.preventDefault();
        $.ajax({
            url: $(this).attr("href"), //Der Ajax-Artikel
            type: 'GET',
            data: ('ajax'),
            success: function (data) {
                $.featherlight(data);
            },
            error: function (a, b, c) {
                console.log(a, b, c);
            }
        });
    });

    $(window).scroll(function () {
        resizeHeaderOnScroll();
    });
    $('.fullscreenVideo-slider').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        fade: true,
        speed: 1000,
        infinite: false
    });
    $('.content-slider').slick({
        autoplay: true,
        fade: true,
        autoplaySpeed: 5000,
        arrows: false,
        speed: 1000,
        infinite: false,
        dots: true,
        appendDots: $('.module--content-slider .dotholder')
    });
    $('.textVideo-slider').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        fade: true,
        speed: 1000,
        infinite: false,
        adaptiveHeight: true
    });
    $('.slideboxMobile').slick({
// autoplay: true,
//autoplaySpeed: 5000,

        adaptiveHeight: true,
        arrows: false,
        fade: false,
        speed: 1000,
        infinite: false

    });
    $('.slideboxDesktopTest').slick({
// autoplay: true,
//autoplaySpeed: 5000,
        slidesToShow: 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
        //arrows: false,
        fade: false,
        speed: 1000,
        infinite: false

    });
    $('.slideboxMobile #slideboxswitch').click(function (e) {
        e.preventDefault();
        //alert("alarm");
        $('html, body').animate({
            scrollTop: $(".slideboxMobile").offset().top - 120
        }, 200, function () {
            $('.slideboxMobile').slick('slickNext');
        });
    });
    $('.slideboxMobile #slideboxswitchback').click(function (e) {
        e.preventDefault();
        //alert("alarm");
        $('html, body').animate({
            scrollTop: $(".slideboxMobile").offset().top - 120
        }, 200, function () {
            $('.slideboxMobile').slick('slickPrev');
        });
    });
    $('.slideboxMobile').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        var currentSlide = $('.slideboxMobile').slick('slickCurrentSlide');
        if (currentSlide == 1) {
            $('.slideboxMobile .slideboxContent').addClass('opacity1');
            $('.slideboxMobile .slideboxContent').animateCss('fadeIn', function () {

            });
        } else {
            $('.slideboxMobile .slideboxContent').removeClass('opacity1');
        }
        console.log(currentSlide);
    });
    $('.bigtext').each(function () {
        $(this).bigtext();
    });
    $('.slideboxDesktop #slideboxswitch').click(function (e) {
        e.preventDefault();
        switchSidebox();
    });
    $('.slideboxDesktop #slideboxswitchback').click(function (e) {
        e.preventDefault();
        switchSidebox();
    });
    $('.scrollToEnd').click(function () {
        $('html, body').animate({
            scrollTop: $(".theEnd").offset().top - 120
        }, 200);
    });
    $('#fullscreenToggle').on('click', function () {
        $('body').addClass('blur');
    });
    $('#fullscreenMenu').on('shown.bs.modal', function (e) {
//$('.main-nav').addClass('main-nav-animation');
    });
    $('#fullscreenMenu').on('hidden.bs.modal', function (e) {
        $('body').removeClass('blur');
    });
    var myVideo = document.getElementById("textvideo");
    $('#textvideo').waypoint(function () {
        myVideo.play();
    }, {
        offset: '50%', // middle of the page
        triggerOnce: true // just my preference...
    });

    resizeHeaderOnScroll();
});
$.fn.extend({
    animateCss: function (animationName, callback) {
        var animationEnd = (function (el) {
            var animations = {
                animation: 'animationend',
                OAnimation: 'oAnimationEnd',
                MozAnimation: 'mozAnimationEnd',
                WebkitAnimation: 'webkitAnimationEnd',
            };
            for (var t in animations) {
                if (el.style[t] !== undefined) {
                    return animations[t];
                }
            }
        })(document.createElement('div'));
        this.addClass('animated ' + animationName).one(animationEnd, function () {
            $(this).removeClass('animated ' + animationName);
            if (typeof callback === 'function')
                callback();
        });
        return this;
    },
});
var is_animating = false;
function switchSidebox() {
    var container = $('.module--slidebox .slideboxWrapper');

    if (is_animating == false) {

        if (container.hasClass('movedLeft')) {
//            $('html, body').animate({
//                scrollTop: $(".slideboxDesktop").offset().top - 120
//            }, 200, function () {
                hideSidebox();
//            });

        } else {
//            $('html, body').animate({
//                scrollTop: $(".slideboxDesktop").offset().top - 120
//            }, 200, function () {
                showSidebox();
//            });

        }
    }
}
function showSidebox() {
    is_animating = true;
    $('.module--slidebox .slideboxWrapper .first-part').removeClass('os-animation');
    $('.module--slidebox .slideboxWrapper .first-part').removeClass('fadeIn');
    $('.module--slidebox .slideboxWrapper .first-part').removeClass('animated');
    $('.module--slidebox .slideboxWrapper .third-part').removeClass('fadeOut');
//    $('.module--slidebox .slideboxWrapper .first-part').animateCss('fadeOut', function () {
        $('.module--slidebox .slideboxWrapper .first-part').css({
            'opacity': '0'
        });
//    });
    $('.module--slidebox .slideboxWrapper').addClass('movedLeft');
    $('#slideboxswitch').animateCss('fadeOut', function () {
        $('#slideboxswitch').addClass('d-none');
        $('#slideboxswitchback').removeClass('d-none');
        $('#slideboxswitchback').animateCss('fadeIn');
    });
    setTimeout(function () {
        $('.third-part').removeClass('d-none');
        $('.third-part').animateCss('fadeIn');
        $('.drop1').removeClass('d-none');
        $('.drop1').animateCss('bounceIn', function () {
            $('.drop2').removeClass('d-none');
            $('.drop2').animateCss('bounceIn', function () {
                $('.drop3').removeClass('d-none');
                $('.drop3').animateCss('bounceIn', function () {
                    is_animating = false;
                });
            });
        });
    }, 1000);
}


function hideSidebox() {
    is_animating = true;
    $('.third-part').animateCss('fadeOut');
    $('.third-part').addClass('d-none');
    $('.drop1').animateCss('fadeOut', function () {
        $('.drop1').addClass('d-none');
    });
    $('.drop2').animateCss('fadeOut', function () {
        $('.drop2').addClass('d-none');
    });
    $('.drop3').animateCss('fadeOut', function () {
        $('.drop3').addClass('d-none');
        $('.module--slidebox .slideboxWrapper').removeClass('movedLeft');
        $('.module--slidebox .slideboxWrapper .first-part').css({
            'opacity': '1'
        });
        $('.module--slidebox .slideboxWrapper .first-part').animateCss('fadeIn', function () {

        });
        $('#slideboxswitchback').animateCss('fadeOut', function () {
            $('#slideboxswitchback').addClass('d-none');
            $('#slideboxswitch').removeClass('d-none');
            $('#slideboxswitch').animateCss('fadeIn');
            is_animating = false;
        });
    });
}

function resizeHeaderOnScroll() {

    var distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = 75,
            headerEl = document.getElementById('aqualiburheader');
    if (distanceY > shrinkOn) {
        headerEl.classList.add("scroll");
        $('.animated-exit:visible').removeClass('dropin');
    } else {
        headerEl.classList.remove("scroll");
        $('.animated-exit').addClass('dropin');
    }
}

function onScrollInit(itemcode, trigger) {
    trigger.each(function () {
        var osTrigger = $(this);
        items = $(this).find(itemcode);
        items.each(function () {
            var osElement = $(this),
                    osAnimationClass = osElement.attr('data-os-animation'),
                    osAnimationDelay = osElement.attr('data-os-animation-delay');
//            if (osAnimationClass.indexOf("fade") >= 0) {
//                osElement.css({
//                    'opacity': '0',
//                    '-webkit-animation-delay': osAnimationDelay,
//                    '-moz-animation-delay': osAnimationDelay,
//                    'animation-delay': osAnimationDelay
//                });
//            } else {
                osElement.css({
                    'opacity': '0',
                    '-webkit-animation-delay': osAnimationDelay,
                    '-moz-animation-delay': osAnimationDelay,
                    'animation-delay': osAnimationDelay
                });
//            }
            osTrigger.waypoint(function () {
                osElement.addClass('animated').addClass(osAnimationClass);
            }, {
                offset: '80%'
            });
        });
    });
}

